.notFoundPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: var(--xx-large);
    line-height: 1em;
    margin-bottom: 5px;
  }
  h2 {
    font-size: var(--large);
    margin-bottom: 5px;
  }
}