@import "../../styles/vars";
@import "../../styles/mixins";

.credits {
  display: flex;
  align-items: center;

  cursor: pointer;

  p {
    font-size: 10px;

    margin-left: 30px;

    color: var(--secondary_text_color);


    @include breakpoint($mobileWidth) {
      display: none;
    }
  }

  .logo {
    display: flex;
    align-items: center;

    svg:first-of-type {
      margin-right: 5px;
      width: 17px;
      height: 24px;

      rect {
        fill: var(--primary_color);
      }
    }

    svg:last-of-type {
      width: 90px;
      height: 12px;
    }
  }
}