.input {
  padding: 13px 18px;

  font-weight: 400;
  font-family: inherit;
  font-size: var(--medium);
  line-height: 1.1em;

  border: 0.5px solid var(--border_color);
  border-radius: var(--border_radius);
  background: none;
  outline: none;
  color: var(--dark_color)
}

.selectWrapper {
  padding: 10px;

  border-radius: var(--border_radius);
  border: 0.5px solid var(--border_color);
  background: transparent;

  .select {
    padding: 0 5px;
    width: 100%;

    font-family: inherit;
    font-weight: 400;
    font-size: var(--medium);
    line-height: 1em;

    border: none;
    outline: none;
    background: transparent;
    color: var(--dark_color)
  }
}



.textarea {
  padding: 12px 12px;
  height: 100px;

  font-family: inherit;
  font-weight: 400;
  font-size: var(--medium);
  line-height: 1.2em;

  resize: vertical;
  border: .5px solid var(--light_gray);
  border-radius: var(--border_radius);
  background: var(--light_gray);
  outline: none;
  color: var(--dark_color)
}

.colorInput {
  padding: 12px 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  border: .5px solid var(--border_color);
  border-radius: var(--border_radius);

  .colorPickerWrapper {
    width: 80%;

    position: relative;
    height: 21px;
  }

  .color {
    height: 21px;

    border-radius: 2px;
    background-color: var(--dark_color);
  }

  .colorPicker {
    position: absolute;
    right: 0;
  }

  .colorHash {
    font-weight: 400;
    font-size: var(--small-2);
    line-height: 1.2em;
  }
}

.fileInput {
  padding: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: var(--medium);

  border: 1px solid var(--light_gray);
  background: var(--light_gray);
  border-radius: var(--border_radius);
  cursor: pointer;

  input[type=file] {
    width: 20px !important;
    opacity: 0;
    position: absolute;
    z-index: -1;
    visibility: hidden;
  }

  span {
    font-weight: 400;
    font-size: var(--small-2);
    line-height: 1em;

    color: var(--dark_color)
  }
}
