.actionMessage {
  position: fixed;
  top: 20px;
  left: 50%;
  width: 95%;
  z-index: 4000;

  padding: 11px;
  align-items: center;
  justify-content: space-between;
  display: none;

  opacity: 0;
  transform: translateY(-100%) translateX(-50%);
  color: var(--primary_color);
  background-color: var(--dark_color);
  border-radius: var(--border_radius_m);
  transition: all .3s ease-in-out;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--primary_color);
    }
  }

  .end {
    display: flex;
    align-items: center;
  }

  .countdown {
    margin-right: 18px;
    font-size: var(--small);
  }

  .closeButton {
    path {
      fill: var(--primary_color);
    }
  }

  .actionMessageMain {
    display: flex;
    align-items: center;
  }

  h3 {
    margin: 0 10px;

    font-weight: 600;
    font-size: var(--small);
    line-height: 1em;
    text-transform: uppercase;
  }

  p {
    font-weight: 400;
    font-size: var(--small);
    line-height: 1em;
  }

  &.error {
    color: var(--red);

    svg {
      path {
        fill: var(--red);
      }
    }

  }

  &.active {
    display: flex;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}