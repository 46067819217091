@import "../../styles/vars";
@import "../../styles/mixins";

.modalContentInner {
  padding: var(--box_padding);
  width: 790px;

  background: var(--light_color);
  border-radius: var(--border_radius);

  .header {
    width: 100%;
    margin-bottom: var(--medium);
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin-bottom: 0;

      flex: 1;
      font-size: var(--large);
      line-height: 1em;
    }

  }

  .body {
    p {
      margin-bottom: var(--medium);
    }

    h1 {
      font-size: var(--x-large);
    }

    input {
      width: 100%;
      margin-bottom: var(--medium);
    }

    button {
      width: 100%;
    }
  }

  .help {
    margin-top: 15px;

    a {
      color: var(--blue);
      text-decoration: underline;
    }
  }

  .confirmText {
    font-weight: 400;
    font-size: var(--medium);
  }

  .switchWrapper {
    width: 100%;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 500;
    }
  }

  .fieldWrapper {
    margin-bottom: 1em;

    &:last-of-type {
      margin-bottom: 0;
    }

    h3 {
      text-align: left;
      margin-bottom: 5px;

      font-size: var(--medium);
    }

    input,
    textarea {
      width: 100%;
    }
  }

  .footer {
    margin-top: 1em;
    display: flex;
    justify-content: center;

    button:first-child {
      margin-right: 1em;
    }
  }

  &.customers {
    width: 600px;

    .header {
      margin-bottom: 30px;

      svg:first-of-type {
        width: 25px;
        height: 25px;

        margin-right: 10px;

        path {
          fill: var(--primary_color);
        }
      }

      h1 {
        flex: 0;
      }
    }

    h1 {
      margin-bottom: 10px;
      font-size: var(--xx-large);
    }

    .footer {
      display: flex;
      justify-content: center;
    }
  }

  @include breakpoint($tabletWidth) {
    width: 100%;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  background-color: var(--modal_background_color);
  opacity: 0;
  transform: translate(0);
  transition: all 0.2s linear;

  &.visible {
    opacity: 1;
    transform: translate(0);
    transform: scale(1);
  }

  .modalContent {
    opacity: 0;
    transform: scale(0.3);
    transition: all 0.4s ease-in-out;

    border-radius: 15px;

    &.visible {
      opacity: 1;
      transform: scale(1);
    }

    @include breakpoint($mobileWidth) {
      width: 100%;
      padding: 0 10px;
    }
  }
}
