@import "../../styles/vars";
@import "../../styles/mixins";

.footer {
  .links {
    margin-top: 10px;
    display: flex;

    list-style: none;

    li {
      margin-right: var(--x-medium);

      font-size: 10px;

      a {
        color: var(--secondary_text_color);
      }
    }
  }

  @include breakpoint($mobileWidth) {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
  }
}