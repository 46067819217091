.checkoutError {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  text-align: center;

  svg {
    margin-bottom: 5px;
  }

  h1 {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: var(--xxx-large);
    line-height: 1em;
  }

  p {
    max-width: 306px;
    font-size: var(--small-2);
  }

}