.paymentMethod {
  display: flex;
  align-items: center;
  padding: 10px;

  font-size: var(--small-2);

  background: transparent;
  border: 1px solid var(--border_color);
  border-radius: var(--border_radius);
  cursor: pointer;

  transition: all .2s linear  ;

  &.active,
  &:hover {
    background: var(--light_gray);
    border-color: var(--light_gray);
  }

  img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }

  .tickSVG {
    margin-left: auto;
  }
}