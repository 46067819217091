.button {
  padding: 13px 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: inherit;
  font-size: var(--medium);
  font-style: normal;
  font-weight: 400;

  outline: none;
  color: var(--dark_color);
  border-radius: var(--border_radius_m);
  background: var(--light_gray);
  border: none;
  cursor: pointer;

  svg, img {
    margin-left: 8px;

    path {
      fill: var(--dark_color);
    }
  }

  &.secondary {
    background-color: var(--light_gray);
    color: var(--blue);
    border-color: transparent;
  }

  &.third {
    background-color: transparent;
    color: var(--dark_color);
    border: .5px solid var(--border_color);
  }

  &.transparent {
    background-color: transparent;
    color: var(--dark_color);
    border-color: transparent;
  }

  &.danger {
    background-color: var(--red_transparent);
    color: var(--red);
    border-color: transparent;
  }

  &.primary {
    background-color: var(--primary_color);
    color: var(--dark_color);
    border-color: transparent;
  }

  &.small {
    font-size: var(--small);
    line-height: 1em;
  }

  &:disabled {
    background-color: var(--gray);
    color: var(--secondary_text_color);
  }

  .spinner {
    margin: 0;
    margin-left: 8px;
    margin-top: 2px;
    width: 15px;
    height: 15px;

    circle {
      stroke: var(--secondary_text_color)
    }
  }
}