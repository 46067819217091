@import "../../styles/vars";
@import "../../styles/mixins";

.paymentMainDetail {
  padding: var(--box_padding);
  padding-top: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;

  border-right: 1px solid var(--border_color);


  @include breakpoint($mobileWidth) {
    padding: 30px var(--box_padding);
    padding-bottom: 0;
    width: 100%;
    background: none;

    .footerWrapper {
      display: none;
    }
  }

  .backButton {
    display: flex;
    align-items: center;

    font-size: 10px;

    border: none;
    background: none;
    cursor: pointer;
    color: var(--secondary_text_color);

    svg {
      margin-right: 10px;

      path {
        fill: var(--secondary_text_color);
      }
    }

    @include breakpoint($mobileWidth) {
      display: none;
    }
  }

  .main {
    margin-bottom: 65px;
    max-width: 488px;

    @include breakpoint($mobileWidth) {
      max-width: none;
      width: 100%;
      margin-bottom: 0;
    }

    h1 {
      margin-bottom: 15px;
      font-weight: 400;
      line-height: 1em;
      font-size: var(--xx-large);
    }

    .addPromoCodeButton {
      width: 100%;
      margin-bottom: var(--medium);
      padding-bottom: var(--medium);
      display: flex;
      align-items: center;

      color: var(--blue);
      border: 0;
      border-bottom: 1px solid var(--border_color);
      outline: 0;
      background: transparent;
      cursor: pointer;

      svg {
        margin-right: 10px;
      }
    }

    .promoCodeForm {
      margin-bottom: var(--medium);
      padding-bottom: var(--medium);
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-bottom: 1px solid var(--border_color);

      input {
        width: 77%;
      }

      button {
        width: 20%;
      }
    }

    .detailWrapper {
      padding-bottom: var(--medium);
      margin-bottom: var(--medium);
      display: flex;
      justify-content: space-between;

      color: var(--secondary_text_color);
      border-bottom: 1px solid var(--border_color);

      span {
        color: var(--dark_color);
      }

      &.total {
        font-size: var(--medium);
      }

      &:last-of-type {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
      }


    }

    .header {
      margin-bottom: var(--xx-large);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .companyInfo {
        display: flex;
        align-items: center;

        h2 {
          font-weight: 400;
          font-size: var(--small-2);
          line-height: 1em;

          color: var(--secondary_text_color);
        }

        p {
          font-size: var(--medium);
          font-weight: 600;
        }
      }

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        width: 45px;
        height: 45px;

        font-size: var(--large);
        text-transform: uppercase;

        border-radius: 50%;
        overflow: hidden;
        background: var(--dark_color);
        color: var(--light_color);

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
        }

        &.noBg {
          background: transparent;
        }
      }


      @include breakpoint($mobileWidth) {
        flex-direction: column;
        align-items: center;

        .companyInfo {
          flex-direction: column;
          align-items: center;

          text-align: center;

          p {
            font-size: var(--large);
            font-weight: 400;
          }
        }

        .logo {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }
    }
  }

  .footer {

  }
}