@import "./styles/vars";
@import "./styles/mixins";

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input::placeholder {
  color: var(--secondary_text_color);
}

body {
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

  background-color: var(--light_color);
  font-size: var(--medium);
  color: var(--dark_color);
}

.App {
  max-width: 1300px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;

  @include breakpoint($mobileWidth) {
    flex-direction: column;
  }
}

.footerWrapper {
  display: none;
  padding: 0 var(--box_padding);

  @include breakpoint($mobileWidth) {
    display: block;
  }
}

.horLine {
  margin: 20px 0;
  width: 100%;
  height: .5px;
  display: none;

  background: var(--light_transparent);

  @include breakpoint($mobileWidth) {
    display: block;
  }
}

:root {
  --primary_color: #A7E23D;
  --primary_color_transparent: rgba(167, 226, 61, 0.1);
  --main_color: #ffffff;
  --dark_color: #191919;
  --light_color: #ffffff;
  --light_gray:#F8F8F8;
  --gray: rgba(25, 25, 25, 0.15);
  --light_transparent: rgba(25, 25, 25, 0.05);
  --pink: #C11B40;
  --red: #F83939;
  --red_transparent: rgba(248, 57, 57, 0.05);
  --pink_transparent: #C11B400D;
  --green: #4F841A;
  --green_transparent: #4F841A0D;
  --blue: #305AD6;
  --blue_transparent: #2D55B90D;
  --border_color: rgba(31, 31, 31, 0.05);
  --secondary_text_color: #929292;
  --border_radius: 5px;
  --border_radius_m: 6px;
  --shadow_color: rgba(14, 19, 6, 0.05);
  --modal_background_color: rgba(0, 0, 0, 0.55);

  --small: 12px;
  --small-2: 14px;
  --medium: 16px;
  --x-medium: 18px;
  --xx-medium: 20px;
  --large: 24px;
  --x-large: 28px;
  --xx-large: 40px;
  --xxx-large: 48px;
  --box_padding: 25px;

  @include breakpoint($mobileWidth) {
    --x-large: 24px;
    --xx-large: 30px;
    --xxx-large: 36px;
    --small-2: 12px;
    --medium: 14px;
    --x-medium: 16px;
    --xx-medium: 18px;
    --large: 20px;

    --box_padding: 15px;
  }
}