@import "../../styles/vars";
@import "../../styles/mixins";

@keyframes loadingCircle {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}


.paymentStep {
  padding: var(--box_padding);
  padding-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;

  width: 50%;

  @include breakpoint($mobileWidth) {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .buttonWrapper {
    position: absolute;
    top: var(--box_padding);
    right: var(--box_padding);

    @include breakpoint($mobileWidth) {
      width: calc(100% - var(--box_padding) * 2);
      position: absolute;
      top: auto;
      bottom: var(--box_padding);
      left: var(--box_padding);

      button {
        width: 100%;
      }
    }
  }

  .h3 {
    margin-bottom: var(--medium);
    font-size: var(--medium);
    font-weight: 400 ;
  }

  .paymentInfo {
    margin-bottom: 25px;

    h2 {
      font-weight: 400;
      font-size: var(--xx-large);
      line-height: 1em;
    }

    span {
      font-weight: 400;
      font-size: var(--small);

      color: var(--secondary_text_color);
    }
  }

  .signInButton {
    position: absolute;
    top: var(--box_padding);
    right: var(--box_padding);
    width: fit-content;

    border: 0;
    color: var(--blue);
    outline: none;
    background: transparent;
    cursor: pointer;

    @include breakpoint($mobileWidth) {
      display: none;
    }
  }

  .choosePaymentMethod {
    h3 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: var(--medium);
    }
  }



  .fieldWrapper {
    margin-bottom: var(--xx-medium);

    p {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: var(--medium);
    }

    .required {
      margin-left: -2px;
      color: var(--red);
    }

    .fieldHelpText {
      display: block;
      width: 100%;
      margin-top: var(--medium);
      text-align: right;
      margin-left: auto;
      font-size: var(--small);

      color: var(--secondary_text_color);

      a {
        color: var(--blue);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    input {
      width: 100%;
    }
  }

  .brickInputs {
    input {
      padding: 10px 18px;
      width: 50%;
      border-radius: 0;

      &:nth-child(2) {
        border-right: 0;
      }

      &:first-child {
        width: 100%;

        border-bottom: 0;
        border-radius: var(--border_radius) var(--border_radius) 0 0;
      }

      &:nth-child(4) {
        width: 100%;

        border-top: 0;
        border-radius: 0 0 var(--border_radius) var(--border_radius);
      }
    }
  }

  .agreementCheckbox {
    margin-bottom: var(--xx-large);
  }

  .agreementText {
    margin-top: 10px;
    text-align: center;
    font-size: 10px;

    color: var(--secondary_text_color);

    a {
      color: var(--dark_color);
    }
  }

  .paymentMethods {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;

    @include breakpoint($mobileWidth) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .totalWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 20px;
    }

    span {
      font-size: var(--large);
      font-weight: 500;
    }
  }

  .stepInner {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin-bottom: 4px;
        font-weight: 400;
        font-size: var(--xx-large);
        line-height: 1em;
      }

      .timer {
        display: flex;
        justify-content: flex-end;
        width: 100px;

        font-size: var(--small-2);
      }

      p {
        max-width: 482px;
        font-size: var(--small-2);
      }

      &.mbMedium {
        margin-bottom: var(--x-medium);
      }
    }

    .buttons {
      margin-bottom: var(--x-medium);
      display: flex;
      justify-content: space-between;

      .bigButton {
        width: 48%;
        margin-bottom: 0;
      }
    }

    .bigButton {
      margin-bottom: var(--x-medium);
      width: 100%;
      padding: var(--x-medium);
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: inherit;
      font-size: var(--medium);

      background: transparent;
      outline: 0;
      border-radius: var(--border_radius);
      border: 1px solid var(--border_color);
      cursor: pointer;

      svg {
        margin-right: var(--small);
      }

      &:disabled {
        background: var(--light_gray);
        color: var(--secondary_text_color);
        cursor: progress;

        svg {
          path {
            stroke: var(--secondary_text_color);
          }
        }
      }
    }

    .statusIcon {
      margin-bottom: 20px;
    }

  }

  .networkWrapper {
    margin-bottom: var(--medium);
    padding: 17px;

    border: .5px solid var(--gray);
    border-radius: var(--border_radius);
    transition: all .2s linear;
    cursor: pointer;

    .head {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      img {
        width: 20px;
        height: 20px;

        margin-right: 10px;
      }

      h4 {
        font-weight: 600;
        font-size: var(--small-2);
      }
    }

    p {
      font-size: var(--small-2);
    }

    &:hover,
    &.active {
      border-color: var(--dark_color);
    }

  }

  .transactionInfo {
    padding: 25px;
    margin-bottom: 25px;

    background: transparent;
    border: .5px solid var(--border_color);
    border-radius: var(--border_radius);

    .transactionInfoItem {
      margin-bottom: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-of-type {
        margin-bottom: 0;
      }

      p, span {
        font-size: var(--medium);
      }

      .gateway {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }

      span {
        color: var(--secondary_text_color);
      }
    }
  }

  .helpText {
    margin-bottom: 25px;
    text-align: center;

    a {
      color: inherit;
    }
  }

  .checkoutDetails {
    display: flex;
    flex-direction: column;
    align-items: center;

    .qr {
      margin: var(--xx-large) 0;
      width: 232px;
      height: 232px;

    }

    .cryptoDetails {
      width: 100%;
      padding:  8px;

      background: transparent;
      border: .5px solid var(--gray);
      border-radius: 14px;

      .cryptoDetailsItem {
        padding: 8px 15px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-radius: 10px;
        cursor: pointer;
        transition: all linear .2s;

        span {
          display: inline-block;
          margin-bottom: 5px;

          font-size: var(--small);

          color: var(--secondary_text_color);
        }

        svg {
          cursor: pointer;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &:hover {
          background: var(--light_gray);
        }

        .cryptoWrapper {
          display: flex;
          align-items: center;

          img {
            width: 18px;
            height: 18px;

            margin-right: 5px;
          }
        }
      }
    }
  }

  .awaitingPaymentIcon {
    width: fit-content;
    -webkit-animation: loadingCircle infinite 2s linear;
    -o-animation: loadingCircle infinite 2s linear;
    animation: loadingCircle infinite 2s linear;
  }

  .help {
    display: flex;
    margin-top: 15px;
    align-self: flex-start;
    text-align: left;

    font-size: var(--small);

    cursor: pointer;
    color: var(--blue);

    svg {
      margin-left: 10px;
    }

    @include breakpoint($mobileWidth) {
      width: 100%;
      text-align: center;
    }
  }
}

